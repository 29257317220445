import { AsyncResult } from '@msdyn365-commerce/retail-proxy';
import { IActionContext } from '@msdyn365-commerce/core-internal';
import { IStoreInfo, IStoreSelectorStateManager } from '@msdyn365-commerce-modules/bopis-utilities';

export const GetStoreFromCache = (context: IActionContext): IStoreInfo | undefined => {
    const cacheKey = 'GENERIC-MAPSSSM';
    const cacheObjectType = 'ISTORESELECTORSTATEMANAGER';

    const storeResult = context.get<AsyncResult<IStoreSelectorStateManager>>(cacheObjectType, cacheKey);

    let store: IStoreInfo | undefined;
    if (storeResult) {
        if (Array.isArray(storeResult)) {
            store = storeResult[0].result?.preferredStore;
        } else {
            store = storeResult.result?.preferredStore;
        }
    }

    return store;
};
